.container {    
    display: flex;
    align-items: center;
}

.name {
    margin-top: 10px;
}
.logo {
    width: 150px;
    margin-right: 2px ;
}
  
.column {
    flex: 1;
    padding: 20px;
    margin: 10px; 
}